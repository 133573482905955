// Este arquivo contém funções relacionadas à instalação do PWA (Progressive Web App)

// initInstallPrompt: Configura um listener para capturar o evento 'beforeinstallprompt'
// que é disparado quando o PWA pode ser instalado. O evento é armazenado para uso posterior.

// installPWA: Função assíncrona que mostra o prompt de instalação do PWA ao usuário
// quando chamada. Ela utiliza o evento armazenado anteriormente e registra se o usuário
// aceitou ou recusou a instalação.

// isPWAInstallable: Função que verifica se o PWA pode ser instalado no momento,
// retornando true se houver um prompt de instalação disponível (deferredPrompt existe)
// e false caso contrário.

// A declaração global WindowEventMap adiciona tipagem TypeScript para o evento
// beforeinstallprompt, garantindo suporte adequado do TypeScript para este evento
// específico do PWA.


let deferredPrompt: any;

export const initInstallPrompt = (): void => {
  window.addEventListener('beforeinstallprompt', (e: Event) => {
    // Previne o comportamento padrão
    e.preventDefault();
    // Guarda o evento para usar depois
    deferredPrompt = e;
  });
};

export const installPWA = async (): Promise<void> => {
  if (!deferredPrompt) {
    return;
  }
  // Mostra o prompt de instalação
  deferredPrompt.prompt();
  // Espera pela escolha do usuário
  const { outcome } = await deferredPrompt.userChoice;
  console.log(`Usuário ${outcome === 'accepted' ? 'aceitou' : 'recusou'} a instalação`);
  // Limpa o prompt salvo
  deferredPrompt = null;
};

export const isPWAInstallable = (): boolean => {
  return !!deferredPrompt;
};

// Adicione esta declaração de tipo para o evento beforeinstallprompt
declare global {
  interface WindowEventMap {
    beforeinstallprompt: Event;
  }
}